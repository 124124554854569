import { getFirebaseSingleton } from "@src/lib/firebase";

export const getAuthHeader = async () => {
  const token = await getAuthToken();

  return { Authorization: token };
};

export async function getAuthToken() {
  return getFirebaseSingleton().currentUser?.getIdToken();
}
